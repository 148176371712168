
import { computed, ref, PropType } from 'vue';
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  name: 'MonitoringChart',
  components: { DoughnutChart },
  props: {
    chartData: {
      type: Array as PropType<number[]>,
      default: (): number[] => [],
    },
    title: {
      type: String,
      default: '',
    },
    commonValue: {
      type: Number,
      default: 0
    }
  },
  setup(props: any) {
    const dataValues = ref([...props.chartData]);

    const chartValues = computed<ChartData<'doughnut'>>(() => ({
      datasets: [
        {
          data: dataValues.value,
          backgroundColor: [
            "#FE1A35",
            "#B3B4C9",
            "#46DBC9",
            "#FF9300"
          ]
        },
      ],
    }));

    const options = computed<ChartOptions<'doughnut'>>(() => ({
      cutout: 40,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: props.title,
        },
      },
      centerText: {
        display: true,
        text: "280"
      }
    }));

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData: chartValues,
      options,
      width: 150,
      height: 150
    });

    return {
      options,
      doughnutChartRef,
      doughnutChartProps,
    };
  },
};
