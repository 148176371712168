
import { defineComponent, ref, computed } from 'vue'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { MONITORING_ACTION_TYPES } from '@/store/monitoring/actions'
import Filter from '@/components/particles/MonitoringView/Filter.vue'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import MonitoringTable from '@/components/particles/MonitoringView/MonitoringTable.vue'
import MonitoringCharts from '@/components/particles/MonitoringView/MonitoringCharts.vue'
import { monitoringTableHeaders } from '@/helpers/tableHeaders'

export default defineComponent({
  name: 'MonitoringView',
  components: {
    Filter,
    LoadingSpinner,
    ViewLayout,
    MonitoringTable,
    MonitoringCharts,
  },
  setup() {
    const store = useStore()
    const getMonitoringCancelToken = useCancelToken()
    const getMonitoringDevicesCancelToken = useCancelToken()
    const headerType = ref()
    const headerClient = ref()
    const headerResidence = ref()
    const headerStatus = ref()
    const headerBattery = ref()
    const filterString = ref('')
    const perPage = ref()

    const getMonitoringInfo = async () => {
      try {
        await Promise.all([
          store.dispatch(
            MONITORING_ACTION_TYPES.GET_MONITORING_INFO,
            getMonitoringCancelToken.value
          ),
          store.dispatch(
            MONITORING_ACTION_TYPES.GET_DEVICES,
            getMonitoringDevicesCancelToken.value
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
    }

    getMonitoringInfo()

    const tableFilters = computed(() => [
      headerType.value,
      headerClient.value,
      headerResidence.value,
      headerStatus.value,
      headerBattery.value,
      filterString.value,
    ])
    const monitoringList = computed(() => store.state.monitoring.monitoringInfo)
    const isLoaded = computed(() => store.state.monitoring.isLoaded)

    //filter
    const livingHouses = ref()
    const livingObjects = ref()

    const handleFilter = () => {
      //TODO: Filter by livingHouses.value and/or livingObjects.value
    }

    return {
      monitoringList,
      isLoaded,
      perPage,
      tableFilters,
      headerType,
      headerClient,
      headerResidence,
      headerStatus,
      headerBattery,
      filterString,
      monitoringTableHeaders,
      livingHouses,
      livingObjects,
      handleFilter,
    }
  },
})
